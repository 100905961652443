/** @format */

import React from 'react';
import {Box} from '@chakra-ui/layout';
import Patterns from '../../patterns';
import Layout from '../../components/layout';
import {getContentNode} from '../../lib/api/contentNodes';
import {getSeoFields} from '../../lib/getSeoFields';
import {NextSeo} from 'next-seo';
import getFooter from '../../lib/api/acf/footer';
import mappingFooter from '../../patterns/Footer/mappingFooter';
// import getMainMenu from '../../lib/api/acf/mainMenu';
import mappingMainMenu from '../../patterns/MainMenu/mappingMainMenu';
import {GetStaticPaths, GetStaticProps} from 'next';
import mappingNavBar from '../../patterns/NavBar/mappingNavBar';
import {
	getMainMenu,
	getGlobalMainMenu,
	getParentSiteMainMenu,
	getEventsMenu,
} from '../../lib/api/acf/navBarMenu';
import {identifySubdomain} from '../../utils';
import {getAllPagesOnMainSite} from '../../lib/api';
import DraftModeUI from '../../components/DraftModeUI/Index';

function Index({post, seo, navBar, footer, subdomain, draftMode}) {
	if (!post) return null;

	// console.log(post);
	return (
		<Layout
			seo={post.seo}
			navBar={navBar}
			footer={footer}
			subdomain={subdomain}
			currentUrl={post.uri}>
			<NextSeo {...seo} />
			<Box>
				{draftMode && <DraftModeUI />}
				{post.pageLayout.flexibleContent.map((post: any, index: number) => {
					return (
						<Patterns
							fieldGroupName={post.__typename}
							post={post}
							subdomain={subdomain}
							key={`${post.fieldGroupName}_${index}`}
						/>
					);
				})}
			</Box>
		</Layout>
	);
}

export const getStaticPaths: GetStaticPaths = async () => {
	const allPages = await getAllPagesOnMainSite();

	// console.log(allPages);
	return {
		paths: allPages,
		fallback: true,
	};
};

export async function getStaticProps(context) {
	let { params: { slug, site }, draftMode } = context;
	let subdomain = site || '';

	if (subdomain === 'undefined') {
		subdomain = '';
	}

	// console.log('DBG template path: [...slug]/index');
	const id = `${slug}`;
	const idType = 'URI';
	const post = await getContentNode(id, idType, draftMode, subdomain);
	const seo = !draftMode ? getSeoFields(post) : null;

	let footer = await getFooter(subdomain);
	footer = mappingFooter(footer);

	// Menus
	const globalMainMenu = await getGlobalMainMenu('');
	const parentSiteMenu = await getParentSiteMainMenu('');
	let mainMenu = null;
	if (post && post.link) {
		if (
			post.link.includes('mysites.io/events') ||
			post.link.includes('mysites.io/the-manor') ||
			post.link.includes('mysites.io/weddings') ||
			post.link.includes('mysites.io/celebrations') ||
			post.link.includes('mysites.io/corporate-events') ||
			post.link.includes('mysites.io/filming')
		) {
			mainMenu = await getEventsMenu('');
		} else {
			mainMenu = await getMainMenu('');
		}
	} else {
		mainMenu = await getMainMenu('');
	}

	let navBar: any = [];

	if (mainMenu && globalMainMenu && parentSiteMenu) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		navBar = mappingNavBar({globalMainMenu, parentSiteMenu, mainMenu});
	}

	const subdomainStr = identifySubdomain(post?.link);

	if (!post) return {notFound: true};

	if(!draftMode) {
		draftMode = false;
	}

	return {
		props: {post, seo, navBar, footer, subdomain: subdomainStr, draftMode},
		revalidate: 150,
	};
}

export default Index;
